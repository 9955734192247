@font-face {
    font-family: 'Be Vietnam Pro';
    src: url("../../Assets/BeVietnamPro-Black.ttf") format('truetype');
}

.moldura {
    width: 80%;
    height: 430px;
    border: 6px solid #E53434;
    border-radius: 2vw;
    padding: 1vw;
    margin-top: 9vw;
    margin-left: 10vw;
}

#cirilo{
    width: 25%;
    margin-left: 44%;
    position: absolute;
    top: 305px;
    left: 200px;
}

#balao{
    width: 16vw;
    height: 11vw;
    margin-left: 41vw;
    position: absolute;
    top: 155px;
    left: 390px;
}

#tente{
    font-weight: 300;
}

#testiculo {
    word-spacing: 0px;
    white-space: nowrap;
    line-height: 4px;
}

#erronio {
    display: flex;
    justify-content: space-between;
    margin-left: 4%;
    margin-top: 7%;
    font-size: 1.5em;
} 

#titulo {
    background-color: #E53434;
    color: whitesmoke;
    width: 50%;
    font-size: 60px;
    text-align: center;
    border-radius: 1vw;
    margin: 0 4% 0 3%;
}

.botao-voltar{
    width: 2%;
    height: 5%;
    margin: 1%;
    background-image: url('../../imagens/seta.svg');
    background-color: white;
    background-size: cover;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 114px;
    left: 40px;
}
